import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import clsx from 'clsx';
import GenericContent from '../GenericContent/GenericContent';
import useStyles from './FFHeroBanner.styles';
const FFHomeHeroBanner = (props) => {
    var _a, _b;
    const { headingColor = 'COBALT', contentPosition = 'LEFT', minHeight = 'MEDIUM', isFullWidthImage = false, headingTag = 'H3', } = props;
    const { classes } = useStyles({ linksCount: (_b = (_a = props === null || props === void 0 ? void 0 : props.links) === null || _a === void 0 ? void 0 : _a.length) !== null && _b !== void 0 ? _b : 0, contentPosition });
    const heading = typeof props.heading === 'string' && props.heading.trim()
        ? props.heading.split('/').map((line, index) => (_jsx("span", { className: clsx(classes.headingLine, headingColor && classes[headingColor], contentPosition === 'CENTER' ? classes.centerHeading : ''), children: line.trim() }, index)))
        : props.heading;
    return (_jsx(_Fragment, { children: _jsx(GenericContent, Object.assign({}, props, { heading: heading, classNames: classes, linkStyle: "BUTTON", textAlignment: "LEFT", mobileContentPosition: "OVER", minHeight: minHeight, contentPosition: contentPosition, isFFHeroImage: true, fullWidthImage: true, isFullWidthImage: isFullWidthImage, headingTag: headingTag })) }));
};
export default FFHomeHeroBanner;
